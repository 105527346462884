import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { Prompt, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  TreeSelect,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { CheckOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTextInterpretationStatesQuery, SortOrder } from 'GraphQL/queries/settings/textInterpretationStates.graphql';
import { useUpdateDocumentTextMutation } from 'GraphQL/mutations/updateDocumentText.graphql';
import { useDocumentTextsInterpretationsQuery } from 'GraphQL/queries/documentTextsInterpretations.graphql';
import { useDeleteTextInterpretationMutation } from 'GraphQL/mutations/deleteTextInterpretation.graphql';
import { useMarkCommentsAsReadMutation } from 'GraphQL/mutations/markCommentsAsRead.graphql';
import { usePoliciesQuery } from 'GraphQL/queries/policies.graphql';
import { VALIDATE_MESSAGES } from 'Common/translations';
import { fromGraphql, toGraphql } from 'Common/graphqlTransform';
import { useApplicationCharacteristicsQuery } from 'GraphQL/queries/settings/applicationCharacteristics.graphql';
import { invalidateCache } from 'Common/invalidateCache';
import {
  AttachmentUpload,
  ButtonsBar,
  QuerySelect,
  RichText,
} from 'Components';
import useAuth from 'Hooks/useAuth';
import CommentForm from 'Components/Comment/CommentForm';
import CommentList from 'Components/Comment/CommentList';
import { useUpdateDocumentTextForReviewersMutation } from 'GraphQL/mutations/updateDocumentTextForReviewers.graphql';
import InterpretationSelect from './InterpretationSelect';
import style from './DocumentTextsForm.module.css';

const { TreeNode } = TreeSelect;

const transforms = {
  belongsTo: ['state'],
  hasMany: [],
  date: [],
  attachment: ['attachment'],
};

const reviewerTransforms = {
  belongsTo: ['state'],
};

const interpretationTransformsThere = {
  belongsTo: ['policy'],
  hasMany: ['inputFrom', 'defaultCharacteristics', 'optionalCharacteristics'],
  date: [],
};

const interpretationTransformsBack = {
  belongsTo: ['policy'],
  hasMany: ['inputFromInterpretations', 'defaultCharacteristics', 'optionalCharacteristics'],
  date: [],
};

interface IoutputInterpretation {
  id: number,
  sortId: string,
  subject: string,
  text: {
    code: string
  }
}

interface Iinterpretation {
  examples?: string | null,
  id: number,
  layout?: string | null,
  inputFromInterpretations?: any,
  outputToInterpretations?: IoutputInterpretation[],
  policy?: any,
  productResult?: string | null,
  sortId?: string | null,
  specification?: string | null,
  subject?: string | null,
  defaultCharacteristics?: {
    id: number,
  }[] | null,
  optionalCharacteristics?: {
    id: number,
  }[] | null,
}

interface DocumentTextsFormProps {
  documentText?: {
    code: string,
    content?: string | null,
    documentId: number,
    explanation?: string | null,
    id: number,
    document?: {
      defaultCharacteristics?: {
        id: number | null,
      }[] | null,
      optionalCharacteristics?: {
        id: number | null,
      }[] | null,
    },
    interpretations?: Iinterpretation[] | null,
    name: string,
    state?: {
      id: number | null,
    } | null,
    type: string,
  } | null,
}

interface FormData {
  code?: string
  content: string
  explanation: string
  state: {
    id: number
  }
  stateExplanation: string
  name: string
  type: string
  interpretations?: any
  attachment?: RcFile[]
}

const renderPolicies = (policiesTree: any) => policiesTree.map((p: any) => (
  <TreeNode disabled={p.code.length < 4} key={p.key} value={p.key} title={`${p.code} ${p.name}`} name={p.name}>
    {p.children && renderPolicies(p.children)}
  </TreeNode>
));

const getInterpretationValues = (interpretation: any) => {
  const toGqlProperties = [
    'specification',
    'state',
    'subject',
    'requirements',
    'productResult',
    'examples',
    'sortId',
    'policy',
    'defaultCharacteristics',
    'optionalCharacteristics',
    'inputFrom',
    'periodic',
  ];

  const reducedInterpretation = toGqlProperties.reduce((accumulator, current) => (
    {
      ...accumulator,
      [current]: interpretation[current],
    }), {});

  return { layout: interpretation.layout || null, ...reducedInterpretation };
};

const DocumentTextsForm: FC<DocumentTextsFormProps> = ({ documentText }: DocumentTextsFormProps) => {
  const { documentId } = useParams();
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState<number>(-1);
  const [commentsModal, setCommentsModal] = useState(false);
  const [form] = Form.useForm();
  const { hasRole } = useAuth();

  const [updateDocumentTextMutation, { loading: loadingUpdate }] = useUpdateDocumentTextMutation({
    ...invalidateCache('documentTexts'),
    ...invalidateCache('documentText'),
  });
  const [updateDocumentTextForReviewersMutation] = useUpdateDocumentTextForReviewersMutation({
    ...invalidateCache('documentTexts'),
    ...invalidateCache('documentText'),
  });
  const [deleteTextInterpretationMutation] = useDeleteTextInterpretationMutation({ ...invalidateCache('documentText') });

  const [markCommentsAsRead] = useMarkCommentsAsReadMutation({ ...invalidateCache('comments') });

  const { data: policiesData } = usePoliciesQuery({ fetchPolicy: 'network-only' });
  const { data: document } = useDocumentTextsInterpretationsQuery({
    variables: {
      documentId: Number.parseInt(documentId, 10),
    },
    fetchPolicy: 'network-only',
  });

  const handleUpdate = async (formData: FormData) => {
    const updated = formData.interpretations
      .filter((e: Iinterpretation) => e.id)
      .map((e: Iinterpretation) => toGraphql(e, interpretationTransformsThere, 'UPDATE'))
      .map((e: Iinterpretation) => ({
        data: getInterpretationValues(e),
        where: { id: e.id },
      }));

    const created = formData.interpretations
      .filter((e: Iinterpretation) => !e.id)
      .map((e: Iinterpretation) => toGraphql(e, interpretationTransformsThere, 'CREATE'))
      .map(getInterpretationValues);

    try {
      setIsBlocking(false);
      await updateDocumentTextMutation({
        variables: {
          data: {
            ...toGraphql(formData, transforms, 'UPDATE'),
            interpretations: {
              create: created,
              update: updated,
            },
          },
          id: documentText!.id,
        },
      });
      notification.success({ message: 'Tekst en interpretaties zijn opgeslagen' });
    } catch (e) {
      notification.error({ message: 'Opslaan van tekst en interpretaties is mislukt' });
    }
  };

  const handleReviewerUpdate = async ({ state, stateExplanation }: FormData) => {
    try {
      setIsBlocking(false);
      await updateDocumentTextForReviewersMutation({
        variables: {
          data: { ...toGraphql({ state, stateExplanation }, reviewerTransforms, 'UPDATE') },
          id: documentText!.id,
        },
      });
      notification.success({ message: 'Tekst en interpretaties zijn opgeslagen' });
    } catch (e) {
      notification.error({ message: 'Opslaan van tekst en interpretaties is mislukt' });
    }
  };

  const removeField = (key: number) => {
    const interpretations = form.getFieldValue('interpretations');
    setDeleteModal(-1);
    if (!interpretations.length) return;

    const interpretationId = interpretations[key].id;

    if (interpretationId) {
      try {
        setIsBlocking(false);
        if (interpretations[key].id) {
          deleteTextInterpretationMutation({
            variables: {
              id: interpretations[key].id,
            },
          });
        }
        notification.success({ message: 'De interpretatie is verwijderd' });
      } catch (e) {
        notification.success({ message: 'De interpretatie kan niet worden verwijderd' });
      }
    }

    form.setFieldsValue({
      interpretations: [...interpretations.slice(0, key), ...interpretations.slice(key + 1)],
    });
  };

  const value = documentText ? {
    ...fromGraphql(documentText, transforms),
    interpretations: documentText?.interpretations?.map((int) => ({
      ...fromGraphql(int, interpretationTransformsBack),
      inputFrom: fromGraphql(int, interpretationTransformsBack).inputFromInterpretations,
    })),
  } : undefined;

  const hasOutputTo = useMemo(() => Boolean(documentText?.interpretations?.[deleteModal]?.outputToInterpretations?.length), [deleteModal]);

  const handleCloseComments = () => {
    setCommentsModal(false);
    markCommentsAsRead({
      variables: { documentTextId: documentText!.id },
    });
  };

  useEffect(() => form.resetFields(), [documentText]);

  return (
    <>
      <div className={style.cover} />
      <Form
        className={style.formWithSections}
        form={form}
        layout="vertical"
        initialValues={value}
        onFinish={(hasRole('ADMIN')) ? handleUpdate : handleReviewerUpdate}
        validateMessages={VALIDATE_MESSAGES}
        onFieldsChange={(changedFields) => {
          if (!isBlocking) {
            if (changedFields.some((field) => field.name.toString() !== 'content')) {
              setIsBlocking(true);
            }
          }
        }}
      >
        <section>
          <Prompt
            when={isBlocking && hasRole('ADMIN')}
            message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wil verlaten?"
          />
          <Row gutter={10}>
            <Col span={3}>
              <Form.Item label="Code">
                <Input value={documentText?.code} />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label="Naam"
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Type"
                name="type"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={19}>
              <Form.Item
                label="Tekst"
                name="content"
              >
                <RichText />
              </Form.Item>
            </Col>
            <Col span={5}>
              <AttachmentUpload
                label="Bijlage"
                name="attachment"
              />
              <Button
                onClick={() => setCommentsModal(true)}
              >
                Reacties
              </Button>
            </Col>
          </Row>

          <Form.Item
            label="Toelichting"
            name="explanation"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>

          <Row gutter={10}>
            <Col span={6}>
              <Form.Item
                label="Status interpretatie"
                name={['state', 'id']}
                fieldKey={['state', 'id']}
                rules={[{ required: true }]}
              >
                <QuerySelect
                  query={useTextInterpretationStatesQuery}
                  rootKey="textInterpretationStates"
                  defaultScope={{
                    orderBy: { name: SortOrder.Asc },
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item
                label="Toelichting op status interpretatie"
                name="stateExplanation"
              >
                <Input.TextArea
                  rows={1}
                />
              </Form.Item>
            </Col>
          </Row>

          {hasRole('ADMIN', 'REVIEWER') && (
            <ButtonsBar>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingUpdate}
                icon={<CheckOutlined />}
              >
                Opslaan
              </Button>
            </ButtonsBar>
          )}
        </section>

        <Form.List name="interpretations">
          {(fields: { key: number, name: number, fieldKey?: number }[], { add }) => (
            <>
              { fields.map(({ key, name, fieldKey }) => {
                if (fieldKey === undefined) return <section key={key} />;

                const ownId = documentText?.interpretations?.[key]?.id || 0;

                return (
                  <section key={key}>
                    <Collapse
                      activeKey={activeKey}
                      bordered={false}
                      className={style.collapse}
                      onChange={(currentKeys) => setActiveKey(Array.isArray(currentKeys) ? [...currentKeys] : [currentKeys])}
                    >
                      <Collapse.Panel
                        key={key}
                        header={(
                          <Row
                            gutter={10}
                            onClick={(event) => event.stopPropagation()}
                          >
                            <Col span={3}>
                              <Form.Item
                                label="Code"
                                name={[name, 'sortId']}
                                fieldKey={[fieldKey, 'sortId']}
                                rules={[{ required: true }]}
                              >
                                <Input addonBefore={documentText?.code} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Onderwerp"
                                name={[name, 'subject']}
                                fieldKey={[fieldKey, 'subject']}
                                rules={[{ required: true }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={7}>
                              <Form.Item
                                label="Gerelateerd product uit control framework"
                                name={[name, 'policy', 'id']}
                                fieldKey={[fieldKey, 'policy', 'id']}
                              >
                                <TreeSelect
                                  showSearch
                                  style={{ width: '100%' }}
                                  dropdownStyle={{ height: 'auto', overflow: 'auto' }}
                                  placeholder="Selecteer product"
                                  allowClear
                                  filterTreeNode={(searchValue, node) => new RegExp(searchValue, 'gi').test(node?.name)}
                                >
                                  {policiesData?.policies && renderPolicies(policiesData?.policies)}
                                </TreeSelect>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label="Layout"
                                name={[name, 'layout']}
                                fieldKey={[fieldKey, 'layout']}
                              >
                                <Select
                                  placeholder="Selecteer layout"
                                  allowClear
                                >
                                  <Select.Option value="FAT">Vet</Select.Option>
                                  <Select.Option value="CURSIVE">Cursief</Select.Option>
                                  <Select.Option value="UNDERLINE">Onderstreept</Select.Option>
                                  <Select.Option value="RED_LETTERS">Rode letters</Select.Option>
                                  <Select.Option value="YELLOW_BACKGROUND">Gele achtergrond</Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={4} style={{ display: 'flex' }}>
                              <Form.Item
                                label="Periodiek"
                                name={[name, 'periodic']}
                                fieldKey={[fieldKey, 'periodic']}
                                style={{ flexGrow: 1 }}
                              >
                                <Select>
                                  <Select.Option value="MANDATORY">Periodiek verplicht</Select.Option>
                                  <Select.Option value="ADVISED">Periodiek geadviseerd</Select.Option>
                                  <Select.Option value="EVENT_BASED">Event gestuurd</Select.Option>
                                  <Select.Option value="OPTIONAL">Eenmalig</Select.Option>
                                  <Select.Option value="NO">Nee</Select.Option>
                                </Select>
                              </Form.Item>
                              {hasRole('ADMIN') && (
                                <Button
                                  onClick={() => setDeleteModal(key)}
                                  danger
                                  icon={<DeleteOutlined />}
                                  style={{ marginTop: 22, marginLeft: 10, flex: '0 0 32px' }}
                                />
                              )}
                            </Col>
                          </Row>
                        )}
                      >
                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item
                              label="Verplicht indien de organisatie"
                              name={[name, 'defaultCharacteristics']}
                              fieldKey={[fieldKey, 'defaultCharacteristics']}
                            >
                              <QuerySelect
                                allowClear
                                mode="multiple"
                                query={useApplicationCharacteristicsQuery}
                                rootKey="applicationCharacteristics"
                                defaultScope={{
                                  orderBy: { name: SortOrder.Asc },
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Optioneel indien de organisatie"
                              name={[name, 'optionalCharacteristics']}
                              fieldKey={[fieldKey, 'optionalCharacteristics']}
                            >
                              <QuerySelect
                                allowClear
                                mode="multiple"
                                query={useApplicationCharacteristicsQuery}
                                rootKey="applicationCharacteristics"
                                defaultScope={{
                                  orderBy: { name: SortOrder.Asc },
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Form.Item
                          label="Specificatie"
                          name={[fieldKey, 'specification']}
                          fieldKey={[fieldKey, 'specification']}
                          rules={[{ required: true }]}
                        >
                          <Input.TextArea autoSize={{ minRows: 1, maxRows: 8 }} />
                        </Form.Item>

                        <Form.Item
                          label="Specifieke eisen"
                          name={[fieldKey, 'requirements']}
                          fieldKey={[fieldKey, 'requirements']}
                        >
                          <Input.TextArea autoSize={{ minRows: 1, maxRows: 8 }} />
                        </Form.Item>

                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item
                              label="Resultaat"
                              name={[name, 'productResult']}
                              fieldKey={[fieldKey, 'productResult']}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Voorbeelden van bewijs"
                              name={[name, 'examples']}
                              fieldKey={[fieldKey, 'examples']}
                            >
                              <Input.TextArea autoSize={{ minRows: 1, maxRows: 8 }} />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item
                              label="Input van"
                              name={[name, 'inputFrom']}
                              fieldKey={[fieldKey, 'inputFrom']}
                            >
                              <InterpretationSelect ownId={ownId} documentTexts={document?.documentTexts} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Output naar">
                              <ul className={style.outputList}>
                                {documentText?.interpretations?.[fieldKey]?.outputToInterpretations?.map((i) => (
                                  <li key={i.id}>{`${i.text.code} ${i.sortId} - ${i.subject}`}</li>
                                ))}
                              </ul>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  </section>
                );
              })}
              {hasRole('ADMIN') && (
                <Button
                  onClick={() => {
                    add({
                      defaultCharacteristics: (documentText?.document?.defaultCharacteristics || []).map((char) => char.id),
                      optionalCharacteristics: (documentText?.document?.optionalCharacteristics || []).map((char) => char.id),
                      periodic: 'NO',
                    });
                    setActiveKey((keys) => [...keys, `${fields.length}`]);
                  }}
                  type="link"
                  icon={<PlusOutlined />}
                >
                  Interpretatie toevoegen
                </Button>
              )}
            </>
          )}
        </Form.List>

        <Modal
          onCancel={() => setDeleteModal(-1)}
          title="Bevestig uw keuze"
          visible={deleteModal > -1}
          cancelText="Annuleren"
          okText="Ik weet het zeker"
          onOk={() => removeField(deleteModal)}
          okButtonProps={{ danger: true }}
        >
          {hasOutputTo && (
            <Alert
              showIcon
              type="warning"
              message="Let op: het product resultaat van deze interpretatie is gekoppeld als input aan één of meerdere interpretaties. Bij het verwijderen van deze interpretatie wordt deze koppeling ook verwijderd"
              style={{ marginBottom: 20 }}
            />
          )}
          <p>Weet u zeker dat u deze interpretatie wilt verwijderen?</p>
        </Modal>
        {documentText && (
          <Modal
            onCancel={handleCloseComments}
            title="Berichten"
            visible={commentsModal}
            width={1000}
            footer={hasRole('ADMIN', 'REVIEWER') ? (
              <CommentForm documentTextId={documentText?.id} />
            ) : false}
          >
            <CommentList documentTextId={documentText?.id} />
          </Modal>
        )}
      </Form>
    </>
  );
};

export default DocumentTextsForm;
